p {
  margin-top: 20px;
}
#appContainer {
  display: flex; 
}

.app {
  margin:0 auto;
  padding: 20px;
}
.form {
  /* width: 100%; */
  text-align: center;
}
.recapture {
  display: inline-block;
}
.error_message {
  width: 300px;
  text-align: center;
}
.signup_success {
  width: 300px;
  text-align: center;
}
.hook__text {
  display: block;
}
.hook__input,
.hook__button {
  width: 390px;
  font-size: 1.8rem;
}
.hook__input {
  padding: 5px;
}
.hook__button {
  padding: 10px;
  margin-top: 20px;
}

.not_found {
  text-align: center;
}